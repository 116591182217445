import React, { useCallback, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { RouteChildrenProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GET_SCHOOL_BY_ID_REQUEST, SET_SCHOOL_REQUEST } from '../../actions/school.action';
import { selectSchool } from '../../reducer/scool.selectors';
import { SchoolState } from '../../reducer/school.types';
import { Loader } from '../../ui-components/Loader/Loader';
import SchoolLogo from '../../components/SchoolLogo/SchoolLogo';
import { PENDING, SUCCESS } from '../../constants/store.constants';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import PlaceholderBox from '../../components/PlaceholderBox/PlaceholderBox';
import { formatTextValue, isSSR } from '../../utils/utils';
import styles from './SchoolInfoContainer.module.css';
import { TEACHER_ID } from '../../constants/storageKeys';
import { firebaseApp } from '../../config/firebase.config';

const SchoolInfoContainer = ({ match }: RouteChildrenProps) => {
  // @ts-ignore
  const currentTeacherId = match && match.params.id;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentSchool, currentSchoolRequest }: SchoolState = useSelector(selectSchool);
  useEffect(() => {
    if (currentTeacherId) {
      dispatch(GET_SCHOOL_BY_ID_REQUEST(currentTeacherId));
    }
  }, [dispatch, currentTeacherId]);
  const isContendFetched =
    currentSchoolRequest.status === SUCCESS &&
    currentSchool &&
    String(currentSchool.teacherId) === String(currentTeacherId);

  const { currentUser } = firebaseApp.auth();
  const isDemo = !currentUser || currentUser.uid.indexOf('demo') >= 0;

  const handleOnClick = useCallback(() => {
    const { currentUser: user } = firebaseApp.auth();
    const isDemoVersion = !user || user.uid.indexOf('demo') >= 0;
    if (isDemoVersion && !isSSR()) {
      window.localStorage.setItem(TEACHER_ID, String(currentTeacherId));
    } else {
      // update Teacher
      dispatch(SET_SCHOOL_REQUEST(parseInt(currentTeacherId, 10)));
    }
  }, [dispatch, currentTeacherId]);
  return (
    <>
      <Loader show={currentSchoolRequest.status === PENDING} />
      <PlaceholderBox show={!isContendFetched} height="400px" />
      {isContendFetched && currentSchool && (
        <Container className={styles.container}>
          <Row>
            <Col lg={2} xs={12}>
              <SchoolLogo
                withPadding={false}
                logoSrc={currentSchool.teacherLogo}
                alt={`${currentSchool.teacherFirstName}${currentSchool?.teacherLastName}`}
              />
            </Col>
            <Col lg={10} xs={12} className={styles.generalInfoBox}>
              <p className={styles.titleText}>{currentSchool.teacherSchoolName}</p>
              <p className={styles.subTitleText}>
                {`${currentSchool.teacherFirstName} ${currentSchool.teacherLastName}`}
              </p>
            </Col>
          </Row>
          <Row className={styles.contactInfoRow}>
            <Col lg={{ offset: 2, span: 10 }} xs={12}>
              <Row>
                <Col lg={4} xs={12} className={styles.contactInfoBoxItem}>
                  <p className={styles.infoLabel}>{t('drivingSchoolPage.teacher.address')}</p>
                  <p className={styles.infoText}>
                    {currentSchool.teacherAddress}
                    <br />
                    {`${currentSchool.teacherZip} ${currentSchool.teacherCity}`}
                  </p>
                </Col>

                <Col lg={4} xs={12} className={styles.contactInfoBoxItem}>
                  <p className={styles.infoLabel}>{t('drivingSchoolPage.teacher.email')}</p>
                  <p className={styles.infoText}>{formatTextValue(currentSchool.teacherEmail)}</p>
                </Col>

                <Col lg={4} xs={12} className={styles.contactInfoBoxItem}>
                  <p className={styles.infoLabel}>{t('drivingSchoolPage.teacher.website')}</p>
                  <p className={styles.infoText}>{formatTextValue(String(currentSchool.teacherWebsite))}</p>
                </Col>

                <Col lg={4} xs={12} className={styles.contactInfoBoxItem}>
                  <p className={styles.infoLabel}>{t('drivingSchoolPage.teacher.phone')}</p>
                  <p className={styles.infoText}>{formatTextValue(currentSchool.teacherPhone)}</p>
                </Col>

                <Col lg={4} xs={12} className={styles.contactInfoBoxItem}>
                  <p className={styles.infoLabel}>{t('drivingSchoolPage.teacher.mobile')}</p>
                  <p className={styles.infoText}>{formatTextValue(currentSchool.teacherMobile)}</p>
                </Col>

                <Col lg={4} xs={12} className={styles.contactInfoBoxItem}>
                  <p className={styles.infoLabel}>{t('drivingSchoolPage.teacher.cost')}</p>
                  <p className={styles.infoText}>{formatTextValue(String(currentSchool.teacherVoucherValue))}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
      <Container className={styles.controlsContainer}>
        <Row>
          <Col xs={12} className={styles.controlsBox}>
            <Button as={LinkWrapper} to={isDemo ? '/offers' : '/mycockpit'} onClick={handleOnClick} variant="secondary">
              {t('drivingSchoolPage.teacher.button')}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SchoolInfoContainer;
