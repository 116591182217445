export const newTermsTranslation = {
  de: `  <h5>Grundsatz</h5>
    <p>
        Ihre Zufriedenheit als Kunde ist unser oberstes Gebot. Dazu gehört auch die Wahrung Ihrer Privatsphäre und Sicherheit Ihrer Daten. Wir, die Reist Telecom AG, Zweigniederlassung CTM Alder, Glutz-Blotzheim-Strasse 3, 4500 Solothurn (nachfolgend „CTM“ genannt) sind Betreiber dieser Website und somit verantwortlich für die Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten. Dabei unterliegen wir dem Schweizer Recht zum Datenschutz gemäss dem Bundesgesetz über den Datenschutz (DSG). Wir legen grossen Wert auf den Schutz und den ordnungsgemässen Umgang mit Ihren personenbezogenen Daten und erheben, verarbeiten und nutzen diese ausschliesslich in Übereinstimmung mit den nachfolgend beschriebenen Grundsätzen und unter Beachtung der anwendbaren Datenschutzgesetze.
    </p>
    <p> Sie können unsere Website besuchen, ohne Angaben zu Ihrer Person zu hinterlassen.
        Wird ein Service unseres Unternehmens über unsere Website in Anspruch genommen, können personenbezogene Daten erhoben (beispielsweise Name, Anschrift oder E-Mail-Adressen) und verarbeitet werden. Nachfolgend erläutern wir, welche Daten wir erheben, wie diese durch uns verwendet werden und welche Rechte Ihnen im Hinblick auf die Verwendung Ihrer Daten gegenüber uns zustehen.
    </p>

    <h5>Verantwortlicher</h5>
    <p>
        Reist Telecom AG, info@ctmalder.ch, 032 588 50 10 (Im Folgenden: «Wir») ist als Betreiber der Website theorie24.ch Verantwortlicher für die Personendaten der Nutzer (Im Folgenden: «Sie») der Website im Sinne des DSG.
    </p>

    <h6>Ihre personenbezogenen Daten</h6>
    <p>
        Personenbezogene Daten sind alle Angaben, die sich auf eine bestimmte oder bestimmbare natürliche Person beziehen. Dazu gehört zum Beispiel Ihr Name, Ihre Anschrift, Ihre E-Mail-Adresse, Ihre Telefonnummer oder auch Ihre vollständige IP-Adresse. Die Verwendung Ihrer personenbezogenen Daten erfolgt zum Zwecke der Abwicklung unserer angebotenen Dienste bzw. des mit Ihnen bestehenden Vertragsverhältnisses. Ihren Namen und Ihre Anschrift benötigen wir, um Kenntnis zu haben, wer unser Vertragspartner ist, d.h. wem gegenüber wir unsere Leistungen erbringen und abrechnen. Ihre Kontaktdaten (E-Mail-Adresse und Telefonnummer) benötigen wir, um mit Ihnen zu kommunizieren.
    </p>

    <p>
        Diese Daten werden Dritten nur insoweit weitergegeben, als dies zur ordnungsgemässen Abwicklung des Vertrages unbedingt erforderlich ist. Wählt der Kunde auf dem Lernportal eine Fahrschule aus, um damit in den Genuss des Fahrschulgutscheines zu gelangen, erhält die ausgewählte Fahrschule die Adresse des Fahrschülers.
    </p>

    <p>
        «theorie24.ch» bewahrt personenbezogene Kundendaten nur so lange auf, wie dies zur Einhaltung der geltenden Gesetze notwendig oder angemessen ist oder solange sie für die Zwecke, für die sie gesammelt wurden, notwendig sind. «theorie24.ch» löscht Personendaten, sobald diese nicht mehr benötigt werden und auf jeden Fall nach Ablauf der gesetzlich vorgeschriebenen maximalen Aufbewahrungsfrist.
    </p>

    <h5>Erhebung, Verarbeitung und Nutzung personenbezogener Daten</h5>

    <h6>Beim Besuch unserer Website</h6>
    <p>
         können unsere Server temporär jeden Zugriff in einer Protokolldatei speichern. Dabei werden folgende Informationen ohne Ihr Zutun erfasst und bis zur automatischen Löschung gespeichert: anonymisierte IP-Adresse, Datum und Uhrzeit des Zugriffs, Name und URL der abgerufenen Datei, Website, von der aus der Zugriff erfolgt, Betriebssystem Ihres Rechners, verwendeter Browser, Name Ihres Internet-Access-Providers.
    </p>

    <p>
        Die Erhebung und Verarbeitung dieser Daten erfolgt zum Zweck, die Nutzung unserer Website zu ermöglichen (Verbindungsaufbau), die Systemsicherheit dauerhaft zu gewährleisten, die technische Administration der Netzinfrastruktur und die Optimierung unseres Internetangebotes zu ermöglichen, sowie zu Marketing und internen statistischen Zwecken.
    </p>

    <p>
        Die zweckgebundenen Auswertungen ermöglichen es «theorie24.ch», Sie bzw. den Kunden über die Angebote individuell zu informieren.
    </p>

    <p>
        Zudem setzen wir beim Besuch unserer Website Cookies und Tracking-Tools ein. Erläuterungen dazu finden Sie in den entsprechenden Abschnitten dieser Datenschutzerklärung.
    </p>

    <h5>WhatsApp</h5>
    <p>
        Sie können unseren Support auch über den Instant-Messaging-Dienst WhatsApp kontaktieren. WhatsApp ist ein Dienst der WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland, einer Tochtergesellschaft der WhatsApp Inc. mit Sitz in 1601 Willow Road, Menlo Park, California 94025, USA, beide nachfolgend "WhatsApp" genannt. Eine teilweise Verarbeitung von Nutzerdaten findet auf WhatsApp-Servern in den USA statt.
    </p>

    <p>
        Durch die Zertifizierung nach dem EU-US Privacy Shield garantiert WhatsApp jedoch, dass die Datenschutzanforderungen der EU auch bei der Datenverarbeitung in den USA eingehalten werden. Darüber hinaus bietet WhatsApp weitere Informationen zum Datenschutz an.
    </p>

    <p>
        Um unseren Support über WhatsApp zu erhalten, benötigen Sie ein WhatsApp-Benutzerkonto. Welche Daten WhatsApp bei der Anmeldung erhebt, können Sie den vorgenannten Datenschutzhinweisen von WhatsApp entnehmen.
    </p>

    <p>
        Wenn Sie unseren Support über WhatsApp kontaktieren, wird die von Ihnen bei der Anmeldung angegebene Handynummer von WhatsApp verarbeitet. Darüber hinaus werden Ihre IP-Adresse sowie Datum und Uhrzeit Ihrer Anmeldung gespeichert.
    </p>

    <p>
        Sie können den Empfang von Nachrichten durch unseren Support über eine Einstellung in der WhatsApp-Software auf Ihrem Endgerät blockieren.
    </p>

    <h5>Google Ads</h5>
    <p>
        Wir nutzen auf unserer Website das Online-Webprogramm «Google AdWords», einen Dienst der Google Inc. mit Sitz in den USA, und die in diesem Programm enthaltene Möglichkeit zum Conversion-Tracking. Wenn Sie über eine Google-Anzeige auf unsere Website gelangen, legt Google AdWords ein Conversion-Cookie in Ihrem Browser ab, der es uns ermöglicht, Statistiken über die Nutzung unserer Website zu erstellen. Dieses Conversion-Cookie verliert nach spätestens 90 Tagen seine Gültigkeit und wird nicht zu einer Identifikation des Nutzers herangezogen.
    </p>

    <p>
        Weitere Informationen zum Conversion-Tracking, insbesondere auch wie Sie die Erfassung der Daten durch den von Google gesetzten Cookie verhindern können, finden Sie unter folgendem Link: <a href="http://services.google.com/sitestats/de.html">http://services.google.com/sitestats/de.html</a>
    </p>

    <p>
        Die Setzung von Cookies durch unsere Website können Sie in Ihrem Browser jederzeit ganz oder teilweise deaktivieren. Bei deaktivierten Cookies stehen Ihnen allenfalls nicht mehr alle Funktionen unserer Website zur Verfügung.
    </p>

    <p>
        Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> abgerufen werden.
    </p>

    <h5>Google Analytics</h5>
    <p>
        Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. («Google»). Google Analytics verwendet sog. «Cookies», Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.
    </p>

    <p>
        Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
    </p>

    <p>
        Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
    </p>

    <p>
        Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google: <a target="_blank" rel="noopener noreferrer" href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>
    </p>

    <h5>Grundsätze der Datenbearbeitung</h5>
    <p>
        Wir berücksichtigen bei der Datenbearbeitung die Bearbeitungsgrundsätze der Rechtmässigkeit, der Verhältnismässigkeit, der Zweckbindung, der Transparenz – insbesondere die Erfüllung der Informationspflichten – und der Datensicherheit.
    </p>

    <h5>Cookies</h5>
    <p>
        «theorie24.ch» nutzt Cookies, um den Nutzern eine möglichst effiziente und benutzerfreundliche Gestaltung von theorie24.ch zu bieten. Cookies sind kurze Textdateien, die auf dem Gerät des Nutzers gespeichert werden und die eine Analyse der Nutzung der Website und die Wiedererkennung des Browsers eines Nutzers dienen.
    </p>

    <p>
        Beim Besuch von theorie24.ch werden hierfür automatisch unter anderem die IP-Adressen der Nutzer sowie Informationen über den verwendeten Internet-Browser gespeichert. Basierend auf diesen Informationen werden anonyme Statistiken zur Nutzung von theorie24.ch erstellt. Zudem wird es theorie24.ch durch den Einsatz von Cookies möglich, ihre Angebote auf den Nutzer anzupassen.
    </p>

    <p>
        Die meisten Browser sind so eingestellt, dass sie Cookies akzeptieren. Es steht den Nutzern frei, dies in den Browsereinstellungen generell zu unterbinden oder die Einstellung so zu wählen, dass jede Speicherung eines Cookies akzeptiert werden muss. Zudem kann jedes gespeicherte Cookie einzeln wieder gelöscht werden. «theorie24.ch» macht darauf aufmerksam, dass die Deaktivierung oder Löschung eines Cookies dazu führen kann, dass einzelne Teile von theorie24.ch nicht mehr korrekt und vollständig funktionieren.
    </p>

    <h5>Datensicherheit</h5>
    <p>
        Alle Informationen, die Sie an uns übermitteln, werden auf Servern innerhalb der Schweiz gespeichert. Leider ist die Übertragung von Informationen über das Internet nicht vollständig sicher, weshalb wir die Sicherheit, der über das Internet an unsere Website übermittelten Daten nicht garantieren können. Wir sichern unsere Website und sonstigen Systeme jedoch durch technische und organisatorische Massnahmen gegen Verlust, Zerstörung, Zugriff, Veränderung oder Verbreitung Ihrer Daten durch unbefugte Personen ab.
    </p>

    <p>
        Zum Schutz Ihrer persönlichen Daten benutzen wir bei der Online-Zahlungsabwicklung eine der sichersten Technologien, die Sicherheits-Software SSL (Secure Socket Layer). Alle Informationen, die mit dieser sicheren Methode verarbeitet werden, werden verschlüsselt, bevor sie an uns gesendet werden. Wir behalten uns vor, unsere Zahlungserfahrungen einem Informationspool zur Verfügung zu stellen. Dieser kann erfragt werden unter info@ctmalder.ch
    </p>

    <p>
        «theorie24.ch» garantiert nicht, dass die Website theorie24.ch jederzeit verfügbar ist und haftet nicht für eine fehlerhafte Datenkommunikation.
    </p>

    <h5>Änderungen dieser Datenschutzbestimmungen</h5>
    <p>
        Wir behalten uns das Recht vor, diese Datenschutzbestimmungen anzupassen. Diese Datenschutzerklärung ist aktuell gültig und datiert vom 01.09.2023. Sie kann jederzeit auf unserer Webseite von Ihnen abgerufen werden.
    </p>

    <h6>Rechte bezüglich der Personendaten</h6>
    <p>
        Selbstverständlich haben Sie das Recht, auf Antrag Auskunft über die von CTM zu Ihrer Person gespeicherten Daten zu erhalten. Ebenso haben Sie das Recht auf Berichtigung unrichtiger Daten, Sperrung und Löschung. Wenden Sie sich dazu bitte an die im Impressum angegebene Adresse.
    </p>

    <h5>Schlussbestimmungen</h5>
    <p>
        Bei allfälligen Unklarheiten und/oder Widersprüchen zwischen dem deutschen, und/oder dem französischen, italienischen und/oder englischen Text dieser Datenschutzbestimmungen ist ausschliesslich der deutsche Text massgebend.
        Die vorliegende Datenschutzerklärung kann jederzeit geändert werden, wobei die jeweils aktuelle Fassung unter theorie24.ch publiziert wird.
    </p>`,
  fr: `<h5>Principe de base</h5>
    <p>
        Votre satisfaction en tant que client est notre priorité absolue. Cela implique également le respect de votre sphère privée et la sécurité de vos données. Nous, Reist Telecom SA, Succursale CTM Alder, Glutz-Blotzheim-Strasse 3, 4500 Soleure (ci-après dénommée "CTM"), sommes l'exploitant de ce site Internet et donc responsable de la collecte, du traitement et de l'utilisation des données personnelles. Ce faisant, nous sommes soumis au droit suisse en matière de protection des données conformément à la loi fédérale sur la protection des données (LPD). Nous attachons une grande importance à la protection et au traitement correct de vos données personnelles et les collectons, les traitons et les utilisons exclusivement en accord avec les principes décrits ci-après et dans le respect des lois applicables en matière de protection des données.
    </p>
    <p>
        Vous pouvez visiter notre site web sans fournir d'informations sur votre personne.
        Si un service de notre entreprise est utilisé via notre site web, des données personnelles peuvent être collectées (par exemple nom, adresse ou adresses e-mail) et traitées. Nous vous expliquons ci-après quelles données nous collectons, comment nous les utilisons et quels sont vos droits vis-à-vis de nous en ce qui concerne l'utilisation de vos données.
    </p>

    <h5>Responsable</h5>
    <p>
        Reist Telecom SA,info@ctmalder.ch, 032 588 50 10 (ci-après : "Nous") est, en tant qu'exploitant du site Internet theorie24.ch.ch, responsable des données personnelles des utilisateurs (ci-après : "Vous") du site Internet au sens de la LPD.
    </p>

    <h5>Vos données personnelles</h5>
    <p>
        Les données à caractère personnel sont toutes les informations qui se rapportent à une personne physique identifiée ou identifiable. Il s'agit par exemple de votre nom, de votre adresse, de votre adresse e-mail, de votre numéro de téléphone ou encore de votre adresse IP complète. L'utilisation de vos données personnelles a pour but de traiter les services que nous proposons ou la relation contractuelle que nous entretenons avec vous. Nous avons besoin de votre nom et de votre adresse pour savoir qui est notre partenaire contractuel, c'est-à-dire à qui nous fournissons et facturons nos prestations. Nous avons besoin de vos données de contact (adresse e-mail et numéro de téléphone) pour communiquer avec vous.
    </p>

    <p>
        Ces données ne sont transmises à des tiers que dans la mesure où cela est absolument nécessaire au bon déroulement du contrat. Si le client choisit une auto-école sur la plate-forme, pour faire valoir son bon, son nom et son adresse seront transmis à cette auto-école. "theorie24.ch" ne conserve les données personnelles des clients qu'aussi longtemps que cela est nécessaire ou approprié pour respecter les lois en vigueur ou aussi longtemps que cela est nécessaire aux fins pour lesquelles elles ont été collectées. "theorie24.ch" supprime les données personnelles dès qu'elles ne sont plus nécessaires et, en tout état de cause, après l'expiration de la durée maximale de conservation prescrite par la loi.
    </p>

    <h5>Collecte, traitement et utilisation des données à caractère personnel</h5>
    <h6>Lors de la visite de notre site web</h6>
    <p>
        Vous pouvez visiter notre site web sans révéler votre identité. Lorsque vous visitez notre site web, nos serveurs enregistrent temporairement chaque accès dans un fichier journal. Les informations suivantes sont saisies sans intervention de votre part et enregistrées jusqu'à leur suppression automatique : adresse IP anonymisée, date et heure de l'accès, nom et URL du fichier consulté, site web à partir duquel l'accès a eu lieu, système d'exploitation de votre ordinateur, navigateur utilisé, nom de votre fournisseur d'accès à Internet.
    </p>

    <p>
        La collecte et le traitement de ces données ont pour but de permettre l'utilisation de notre site web (établissement de la connexion), de garantir durablement la sécurité du système, de permettre l'administration technique de l'infrastructure du réseau et l'optimisation de notre offre Internet, ainsi qu'à des fins de marketing et de statistiques internes.
    </p>

    <p>
        Les évaluations à des fins spécifiques permettent à "theorie24.ch" de vous informer individuellement ou d'informer le client sur les offres.
    </p>

    <p>
        Nous utilisons en outre des cookies et des outils de suivi lors de la visite de notre site Internet. Vous trouverez des explications à ce sujet dans les paragraphes correspondants de la présente déclaration de protection des données.
    </p>

    <h5>WhatsApp</h5>
    <p>
        Vous pouvez également contacter notre support via le service de messagerie instantanée WhatsApp. WhatsApp est un service de WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irlande, une filiale de WhatsApp Inc. dont le siège social est situé 1601 Willow Road, Menlo Park, California 94025, États-Unis, tous deux dénommés ci-après "WhatsApp". Un traitement partiel des données des utilisateurs a lieu sur les serveurs de WhatsApp aux États-Unis.
    </p>

    <p>
        Grâce à la certification selon le Privacy Shield UE-US, WhatsApp garantit toutefois que les exigences de l'UE en matière de protection des données sont également respectées lors du traitement des données aux États-Unis. En outre, WhatsApp propose des informations supplémentaires sur la protection des données.
    </p>

    <p>
        Pour bénéficier de notre assistance via WhatsApp, vous devez disposer d'un compte d'utilisateur WhatsApp. Pour savoir quelles données WhatsApp collecte lors de l'inscription, veuillez consulter les informations sur la protection des données de WhatsApp mentionnées ci-dessus.
    </p>

    <p>
        Si vous contactez notre support via WhatsApp, le numéro de téléphone portable que vous avez indiqué lors de votre inscription sera traité par WhatsApp. En outre, votre adresse IP ainsi que la date et l'heure de votre connexion sont enregistrées.
    </p>

    <p>
        Vous pouvez bloquer la réception de messages par notre support en paramétrant le logiciel WhatsApp sur votre terminal.
    </p>

    <h5>Google Ads</h5>
    <p>
        Nous utilisons sur notre site web le programme web en ligne "Google AdWords", un service de Google Inc. dont le siège se trouve aux États-Unis, et la possibilité de suivi des conversions contenue dans ce programme. Lorsque vous accédez à notre site web via une annonce Google, Google AdWords place un cookie de conversion dans votre navigateur, ce qui nous permet d'établir des statistiques sur l'utilisation de notre site web. Ce cookie de conversion perd sa validité au plus tard après 90 jours et ne permet pas d'identifier l'utilisateur.
    </p>

    <p>
        Vous trouverez de plus amples informations sur le suivi des conversions, notamment sur la manière dont vous pouvez empêcher la saisie des données par le cookie mis en place par Google, en cliquant sur le lien suivant : <a target="_blank" rel="noopener noreferrer" href="http://services.google.com/sitestats/fr.html">http://services.google.com/sitestats/fr.html.</a>
    </p>

    <p>
        Vous pouvez à tout moment désactiver tout ou partie des cookies de notre site web dans votre navigateur. Si les cookies sont désactivés, il se peut que vous ne disposiez plus de toutes les fonctions de notre site web.
    </p>

    <p>
        Pour plus d'informations et pour consulter les règles de confidentialité de Google, rendez-vous sur <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy?hl=fr">https://policies.google.com/privacy?hl=fr.</a>
    </p>

    <h5>Google Analytics</h5>
    <p>
        Ce site web utilise Google Analytics, un service d'analyse web de Google Inc ("Google"). Google Analytics utilise des "cookies", des fichiers texte qui sont enregistrés sur votre ordinateur et qui permettent d'analyser votre utilisation du site web. Les informations générées par le cookie concernant votre utilisation de ce site web sont généralement transmises à un serveur de Google aux États-Unis et y sont enregistrées. En cas d'activation de l'anonymisation IP sur ce site web, votre adresse IP sera toutefois abrégée au préalable par Google dans les États membres de l'Union européenne ou dans d'autres États signataires de l'accord sur l'Espace économique européen.
    </p>

    <p>
        Ce n'est que dans des cas exceptionnels que l'adresse IP complète est transmise à un serveur de Google aux États-Unis et y est abrégée. Google utilisera cette information dans le but d'évaluer votre utilisation du site, de compiler des rapports sur l'activité du site à destination de son éditeur et de fournir d'autres services relatifs à l'activité du site et à l'utilisation d'Internet. Le cas échéant, Google transmettra également ces informations à des tiers, dans la mesure où cela est prescrit par la loi ou si des tiers traitent ces données pour le compte de Google. L'adresse IP transmise par votre navigateur dans le cadre de Google Analytics ne sera pas recoupée avec d'autres données de Google.
    </p>

    <p>
        Vous pouvez empêcher l'installation des cookies en paramétrant votre navigateur en conséquence ; nous attirons toutefois votre attention sur le fait que, dans ce cas, vous ne pourrez peut-être pas utiliser toutes les fonctions de ce site web dans leur intégralité. En utilisant ce site web, vous acceptez que les données collectées à votre sujet par Google soient traitées de la manière et aux fins décrites ci-dessus.
    </p>

    <p>
        Vous trouverez plus d'informations sur le traitement des données des utilisateurs de Google Analytics dans la déclaration de protection des données de Google : <a target="_blank" rel="noopener noreferrer" href="https://support.google.com/analytics/answer/6004245?hl=fr&sjid=17786072566002935581-EU">https://support.google.com/analytics/answer/6004245?hl=fr&sjid=17786072566002935581-EU.</a>
    </p>

    <h5>Principes du traitement des données</h5>
    <p>
        Lors du traitement des données, nous tenons compte des principes de traitement de la légalité, de la proportionnalité, de la finalité, de la transparence - en particulier le respect des obligations d'information - et de la sécurité des données.
    </p>

    <h5>Cookies</h5>
    <p>
        "theorie24.ch" utilise des cookies afin d'offrir aux utilisateurs une conception aussi efficace et conviviale que possible de theorie24.ch. Les cookies sont de courts fichiers texte qui sont enregistrés sur l'appareil de l'utilisateur et qui servent à analyser l'utilisation du site web et à reconnaître le navigateur d'un utilisateur.
    </p>

    <p>
        Lors de la visite de theorie24.ch, les adresses IP des utilisateurs ainsi que des informations sur le navigateur Internet utilisé sont automatiquement enregistrées à cet effet. Sur la base de ces informations, des statistiques anonymes sur l'utilisation de theorie24.ch sont établies. En outre, l'utilisation de cookies permet à theorie24.ch d'adapter ses offres à l'utilisateur.
    </p>

    <p>
        La plupart des navigateurs sont configurés de manière à accepter les cookies. Les utilisateurs sont libres de l'interdire de manière générale dans les paramètres du navigateur ou de choisir un réglage tel que chaque enregistrement d'un cookie doit être accepté. En outre, chaque cookie enregistré peut être supprimé individuellement. "theorie24.ch" attire l'attention sur le fait que la désactivation ou la suppression d'un cookie peut avoir pour conséquence que certaines parties de theorie24.ch ne fonctionnent plus correctement et complètement.
    </p>

    <h5>Sécurité des données</h5>
    <p>
        Toutes les informations que vous nous transmettez sont stockées sur des serveurs situés en Suisse. Malheureusement, la transmission d'informations par Internet n'est pas entièrement sécurisée, c'est pourquoi nous ne pouvons pas garantir la sécurité des données transmises à notre site Internet par le biais d'Internet. Nous protégeons toutefois notre site web et nos autres systèmes par des mesures techniques et organisationnelles contre la perte, la destruction, l'accès, la modification ou la diffusion de vos données par des personnes non autorisées.
    </p>

    <p>
        Pour protéger vos données personnelles, nous utilisons l'une des technologies les plus sûres lors du traitement des paiements en ligne, le logiciel de sécurité SSL (Secure Socket Layer). Toutes les informations traitées par cette méthode sécurisée sont cryptées avant de nous être envoyées. Nous nous réservons le droit de mettre nos expériences de paiement à la disposition d'un pool d'informations. Celui-ci peut être consulté à l'adresse info@ctmalder.ch
    </p>

    <p>
        "theorie24.ch" ne garantit pas que le site theorie24.ch soit disponible à tout moment et n'est pas responsable d'une communication de données erronée.
    </p>

    <h5>Modifications des présentes dispositions relatives à la protection des données</h5>
    <p>
        Nous nous réservons le droit d'adapter la présente politique de confidentialité. La présente déclaration de protection des données est actuellement en vigueur et date du 01.09.2023. Vous pouvez la consulter à tout moment sur notre site Internet.
    </p>

    <h5>Droits relatifs aux données personnelles</h5>
    <p>
        Vous avez bien entendu le droit d'obtenir, sur demande, des informations sur les données personnelles enregistrées par CTM à votre sujet. De même, vous avez le droit de faire rectifier des données inexactes, de les bloquer ou de les effacer. Pour ce faire, veuillez vous adresser à l'adresse indiquée dans les mentions légales.
    </p>

    <h5>Dispositions finales</h5>
    <p>
        En cas d'ambiguïté et/ou de contradiction entre le texte allemand et/ou le texte français de la présente politique de confidentialité, seul le texte allemand fait foi.
    </p>
    <p>La présente déclaration de protection des données peut être modifiée à tout moment, la version actuelle étant publiée sur theorie24.ch.</p>
`,
  it: `
     <h5>Principio</h5>
    <p>La vostra soddisfazione come clienti è la nostra massima priorità. Ciò include anche la protezione della vostra privacy e la sicurezza dei vostri dati. Noi, Reist Telecom SA, Filiale CTM Alder, Glutz-Blotzheim-Strasse 3, 4500 Solothurn (di seguito "CTM") siamo i gestori di questo sito web e quindi responsabili della raccolta, dell'elaborazione e dell'utilizzo dei dati personali. A tal fine, siamo soggetti alla legge svizzera sulla protezione dei dati, in conformità con la legge federale svizzera sulla protezione dei dati (DSG). Attribuiamo grande importanza alla protezione e al corretto trattamento dei vostri dati personali e li raccogliamo, elaboriamo e utilizziamo esclusivamente secondo i principi descritti di seguito e in conformità con le leggi vigenti sulla protezione dei dati.</p>

    <p>È possibile visitare il nostro sito web senza fornire alcun dato personale. Se si utilizza un servizio della nostra azienda tramite il nostro sito web, è possibile che vengano raccolti ed elaborati dati personali (ad esempio nome, indirizzo o indirizzi e-mail). Di seguito vi spieghiamo quali dati raccogliamo, come li utilizziamo e quali diritti avete in merito all'utilizzo dei vostri dati.</p>

    <h5>Responsabile</h5>
    <p>Reist Telecom SA, info@ctmalder.ch, 032 588 50 10 (di seguito: "Noi"), in qualità di gestore del sito web theorie24.ch, è responsabile dei dati personali degli utenti (di seguito: "Voi") del sito web ai sensi della FADP.</p>

    <h5>I vostri dati personali</h5>
    <p>Per dati personali si intendono tutte le informazioni relative a una persona fisica identificata o identificabile. Si tratta, ad esempio, del vostro nome, del vostro indirizzo, del vostro indirizzo e-mail, del vostro numero di telefono o del vostro indirizzo IP completo. I vostri dati personali vengono utilizzati allo scopo di elaborare i servizi che offriamo o il rapporto contrattuale in essere con voi. Abbiamo bisogno del vostro nome e indirizzo per sapere chi è il nostro partner contrattuale, ossia colui al quale forniamo e fatturiamo i nostri servizi. Abbiamo bisogno dei vostri dati di contatto (indirizzo e-mail e numero di telefono) per comunicare con voi.</p>

    <p>Questi dati vengono trasmessi a terzi solo nella misura in cui ciò è assolutamente necessario per la corretta esecuzione del contratto.</p>
    <p>"theorie24.ch" conserverà i dati personali dei clienti solo per il tempo necessario o appropriato per conformarsi alle leggi vigenti o per il tempo necessario agli scopi per cui sono stati raccolti. "theorie24.ch" cancella i dati personali non appena non sono più necessari e in ogni caso dopo la scadenza del periodo massimo di conservazione previsto dalla legge.</p>

    <h5>Raccolta, trattamento e utilizzo dei dati personali</h5>
    <h6>Quando si visita il nostro sito web</h6>

    <p>Potete visitare il nostro sito web senza rivelare la vostra identità. Quando visitate il nostro sito web, i nostri server salvano temporaneamente ogni accesso in un file di log. Le seguenti informazioni vengono raccolte senza l'intervento dell'utente e memorizzate fino alla cancellazione automatica: indirizzo IP anonimo, data e ora dell'accesso, nome e URL del file consultato, sito web dal quale è stato effettuato l'accesso, sistema operativo del computer, browser utilizzato, nome del provider di accesso a Internet.</p>

    <p>Questi dati vengono raccolti ed elaborati per consentire l'utilizzo del nostro sito web (creazione della connessione), per garantire la sicurezza del sistema su base permanente, per consentire l'amministrazione tecnica dell'infrastruttura di rete e l'ottimizzazione del nostro sito web, nonché per scopi di marketing e statistici interni.</p>

    <p>Le valutazioni relative allo scopo consentono a "theorie24.ch" di informare individualmente l'utente o il cliente sulle offerte.</p>

    <p>Utilizziamo anche cookie e strumenti di tracciamento quando visitate il nostro sito web. Troverete spiegazioni in merito nelle sezioni corrispondenti della presente dichiarazione sulla protezione dei dati.</p>

    <h5>WhatsApp</h5>

    <p>È possibile contattare il nostro supporto anche tramite il servizio di messaggistica istantanea WhatsApp. WhatsApp è un servizio di WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublino 2, Irlanda, una filiale di WhatsApp Inc. con sede legale al 1601 Willow Road, Menlo Park, California 94025, USA, di seguito entrambe denominate "WhatsApp". Il trattamento parziale dei dati degli utenti avviene sui server di WhatsApp negli Stati Uniti.</p>

    <p>Tuttavia, grazie alla certificazione nell'ambito del Privacy Shield UE-USA, WhatsApp garantisce che i requisiti di protezione dei dati dell'UE sono rispettati anche per l'elaborazione dei dati negli Stati Uniti. Inoltre, WhatsApp offre ulteriori informazioni sulla protezione dei dati.</p>

    <p>Per ricevere il nostro supporto tramite WhatsApp, è necessario disporre di un account utente WhatsApp. Per sapere quali dati WhatsApp raccoglie durante la registrazione, consultare le informazioni sulla protezione dei dati di WhatsApp già citate.</p>

    <p>Se contattate il nostro supporto tramite WhatsApp, il numero di cellulare che avete fornito al momento della registrazione verrà elaborato da WhatsApp. Inoltre, verranno memorizzati il vostro indirizzo IP e la data e l'ora della vostra registrazione.</p>

    <p>È possibile bloccare la ricezione dei messaggi da parte del nostro supporto tramite un'impostazione del software WhatsApp sul proprio dispositivo finale.</p>

    <h5>Annunci di Google</h5>

    <p>Sul nostro sito web utilizziamo il programma web online "Google AdWords", un servizio di Google Inc. con sede negli USA, e l'opzione di monitoraggio delle conversioni contenuta in questo programma. Quando accedete al nostro sito web tramite un annuncio di Google, Google AdWords inserisce nel vostro browser un cookie di conversione che ci consente di elaborare statistiche sull'utilizzo del nostro sito web. Questo cookie di conversione perde la sua validità al più tardi dopo 90 giorni e non viene utilizzato per identificare l'utente.</p>

    <p>Ulteriori informazioni sul conversion tracking, in particolare su come impedire la raccolta dei dati da parte del cookie impostato da Google, sono disponibili al seguente link: http://services.google.com/sitestats/it.html
    È possibile disattivare completamente o parzialmente l'impostazione dei cookie da parte del nostro sito web nel proprio browser in qualsiasi momento. Se disattivate i cookie, è possibile che non possiate più utilizzare tutte le funzioni del nostro sito web.</p>

    <p>Ulteriori informazioni e le disposizioni applicabili in materia di protezione dei dati di Google sono disponibili all'indirizzo <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy.">https://policies.google.com/privacy.</a></p>

    <h5>Google Analytics</h5>

    <p>Questo sito web utilizza Google Analytics, un servizio di analisi web fornito da Google, Inc ("Google"). Google Analytics utilizza i cosiddetti "cookie", file di testo che vengono depositati sul vostro computer per consentire al sito web di analizzare come gli utenti utilizzano il sito. Le informazioni generate dal cookie sull'utilizzo del sito web da parte dell'utente vengono solitamente trasmesse a un server di Google negli Stati Uniti e lì memorizzate. Tuttavia, nel caso in cui su questo sito web sia attivata l'anonimizzazione dell'IP, il vostro indirizzo IP viene preventivamente troncato da Google all'interno degli Stati membri dell'Unione Europea o in altri Stati contraenti dell'Accordo sullo Spazio Economico Europeo.</p>

    <p>Solo in casi eccezionali l'indirizzo IP completo verrà trasmesso a un server di Google negli USA e lì abbreviato. Google utilizzerà queste informazioni per valutare l'utilizzo del sito web da parte dell'utente, per redigere rapporti sulle attività del sito web per gli operatori del sito web e per fornire altri servizi relativi alle attività del sito web e all'utilizzo di Internet. Google può anche trasferire queste informazioni a terzi, se ciò è richiesto dalla legge o se tali terzi elaborano le informazioni per conto di Google. L'indirizzo IP trasmesso dal vostro browser nell'ambito di Google Analytics non viene unito ad altri dati di Google.</p>

    <p>Potete rifiutarvi di usare i cookie selezionando l'impostazione appropriata sul vostro browser, ma tenete presente che in tal caso potreste non essere in grado di utilizzare tutte le funzionalità di questo sito web. Utilizzando il presente sito web, l'utente acconsente al trattamento dei propri dati da parte di Google per le modalità e i fini sopraindicati.</p>

    <p>Per ulteriori informazioni sulle modalità di trattamento dei dati degli utenti da parte di Google Analytics, consultare l'informativa sulla privacy di Google: <a target="_blank" rel="noopener noreferrer" href="https://support.google.com/analytics/answer/6004245?hl=it&sjid=12597813127535877744-EU">https://support.google.com/analytics/answer/6004245?hl=it&sjid=12597813127535877744-EU</a></p>

    <h5>Principi di trattamento dei dati</h5>

    <p>Nell'elaborazione dei dati teniamo conto dei principi di legalità, proporzionalità, limitazione delle finalità, trasparenza - in particolare l'adempimento degli obblighi di informazione - e sicurezza dei dati.</p>

    <h5>1.6 Cookie</h5>

    <p>"theorie24.ch" utilizza i cookie per offrire agli utenti un design di theorie24.ch il più efficiente e facile da usare. I cookie sono brevi file di testo che vengono memorizzati sul dispositivo dell'utente e servono ad analizzare l'utilizzo del sito web e a riconoscere il browser dell'utente.</p>

    <p>Quando si visita theorie24.ch, gli indirizzi IP degli utenti e le informazioni sul browser Internet utilizzato vengono memorizzati automaticamente a questo scopo. Sulla base di queste informazioni, vengono compilate statistiche anonime sull'utilizzo di theorie24.ch. Inoltre, l'uso dei cookie consente a theorie24.ch di adattare le proprie offerte all'utente.</p>

    <p>La maggior parte dei browser è impostata per accettare i cookie. Gli utenti sono liberi di impedirlo nelle impostazioni del browser o di selezionare l'impostazione in modo che ogni memorizzazione di un cookie debba essere accettata. Inoltre, ogni cookie memorizzato può essere nuovamente cancellato individualmente. "theorie24.ch" richiama l'attenzione sul fatto che la disattivazione o la cancellazione di un cookie può comportare che singole parti di theorie24.ch non funzionino più correttamente e completamente.</p>

    <h5>Sicurezza dei dati</h5>

    <p>Tutte le informazioni che ci trasmettete sono memorizzate su server in Svizzera. Purtroppo la trasmissione di informazioni via Internet non è completamente sicura, per cui non possiamo garantire la sicurezza dei dati trasmessi al nostro sito web via Internet. Tuttavia, proteggiamo il nostro sito web e altri sistemi con misure tecniche e organizzative contro la perdita, la distruzione, l'accesso, la modifica o la distribuzione dei dati da parte di persone non autorizzate.</p>

    <p>Per proteggere i vostri dati personali, utilizziamo una delle tecnologie più sicure per l'elaborazione dei pagamenti online, il software di sicurezza SSL (Secure Socket Layer). Tutte le informazioni trattate con questo metodo sicuro vengono criptate prima di essere inviate a noi. Ci riserviamo il diritto di rendere disponibili le nostre esperienze di pagamento a un pool di informazioni. Questo può essere richiesto a info@ctmalder.ch</p>

    <p>"theorie24.ch" non garantisce che il sito web theorie24.ch sia sempre disponibile e non è responsabile per la comunicazione di dati difettosi.</p>

    <h5>Modifiche alla presente informativa sulla privacy</h5>

    <p>Ci riserviamo il diritto di modificare la presente informativa sulla privacy. La presente informativa sulla privacy è attualmente valida e datata 01.09.2023 e può essere consultata in qualsiasi momento sul nostro sito web.</p>

    <h5>Diritti relativi ai dati personali</h5>

    <p>L'utente ha naturalmente il diritto di richiedere informazioni sui dati che CTM ha memorizzato su di lui. Avete anche il diritto di far correggere, bloccare e cancellare i dati errati. A tal fine, si prega di contattare l'indirizzo indicato nell'impronta.</p>

    <h5>Disposizioni finali</h5>

    <p>In caso di ambiguità e/o contraddizioni tra il testo tedesco e/o francese delle presenti disposizioni sulla protezione dei dati, prevarrà solo il testo tedesco.
La presente Informativa sulla privacy può essere modificata in qualsiasi momento e l'ultima versione è pubblicata sul sito theorie24.ch.</p>
      `,
  en: `
   <h5>Principle</h5>
    <p>Your satisfaction as a customer is our top priority. This also includes the protection of your privacy and security of your data. We, Reist Telecom AG, Branch office CTM Alder, Glutz-Blotzheim-Strasse 3, 4500 Solothurn (hereinafter referred to as "CTM") are the operator of this website and therefore responsible for the collection, processing, and use of personal data. In doing so, we are subject to Swiss data protection law in accordance with the Swiss Federal Data Protection Act (DSG). We attach great importance to the protection and proper handling of your personal data and collect, process, and use it exclusively in accordance with the principles described below and in compliance with the applicable data protection laws.</p>

    <p>You can visit our website without providing any personal information. If a service of our company is used via our website, personal data may be collected (for example, name, address, or e-mail addresses) and processed. Below we explain what data we collect, how it is used by us, and what rights you have with regard to the use of your data against us.</p>

    <h5>Responsible</h5>
    <p>Reist Telecom SA, info@ctmalder.ch, 032 588 50 10 (hereinafter referred to as "We"), as operator of the website theorie24.ch, is the controller of the personal data of the users (hereinafter referred to as "You") of the website within the meaning of the DPA.</p>

    <h5>Your personal data</h5>
    <p>Personal data is any information that relates to an identified or identifiable natural person. This includes, for example, your name, address, e-mail address, telephone number, or even your complete IP address. Your personal data is used for the purpose of processing our offered services or the contractual relationship existing with you. We need your name and address to know who our contractual partner is, i.e., to whom we provide and invoice our services. We need your contact data (e-mail address and telephone number) in order to communicate with you.</p>

    <p>This data will be disclosed to third parties only to the extent that this is absolutely necessary for the proper execution of the contract. If the customer selects a driving school on the learning portal in order to thereby benefit from the driving school voucher, the selected driving school will receive the address of the learner driver.</p>

    <p>"theorie24.ch" retains personal customer data only as long as necessary or appropriate to comply with applicable laws or as long as it is necessary for the purposes for which it was collected. "theorie24.ch" deletes personal data as soon as it is no longer needed and in any case after the expiry of the maximum retention period prescribed by law.</p>

    <h5>Collection, processing, and use of personal data</h5>

    <h6>When visiting our website</h6>
    <p>You can visit our website without revealing your identity. When you visit our website, our servers temporarily store each access in a log file. The following information is collected without your intervention and stored until automatic deletion: anonymized IP address, date and time of access, name and URL of the accessed file, website from which the access was made, operating system of your computer, browser used, name of your Internet access provider.</p>

    <p>This data is collected and processed for the purpose of enabling the use of our website (connection establishment), to ensure system security on a permanent basis, to enable the technical administration of the network infrastructure and the optimization of our Internet offering, as well as for marketing and internal statistical purposes.</p>

    <p>The purpose-related evaluations enable "theorie24.ch" to inform you or the customer individually about the offers.</p>

    <p>In addition, we use cookies and tracking tools when you visit our website. Explanations can be found in the corresponding sections of this privacy policy.</p>

    <h5>WhatsApp</h5>
    <p>You can also contact our support via the instant messaging service WhatsApp. WhatsApp is a service of WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland, a subsidiary of WhatsApp Inc. with registered office at 1601 Willow Road, Menlo Park, California 94025, USA, both hereinafter referred to as "WhatsApp". Partial processing of user data takes place on WhatsApp servers in the USA.</p>

    <p>However, through certification under the EU-US Privacy Shield, WhatsApp guarantees that the data protection requirements of the EU are also complied with for data processing in the USA. In addition, WhatsApp offers further information on data protection.</p>

    <p>To receive our support via WhatsApp, you need a WhatsApp user account. You can find out which data WhatsApp collects during registration in the aforementioned WhatsApp data protection information.</p>

    <p>If you contact our support via WhatsApp, the cell phone number you provided during registration will be processed by WhatsApp. In addition, your IP address and the date and time of your registration will be stored.</p>

    <p>You can block the receipt of messages by our support via a setting in the WhatsApp software on your end device.</p>

    <h5>Google Ads</h5>
    <p>On our website, we use the online web program "Google AdWords", a service of Google Inc. based in the USA, and the conversion tracking option contained in this program. When you access our website via a Google ad, Google AdWords places a conversion cookie in your browser, which enables us to compile statistics about the use of our website. This conversion cookie loses its validity after 90 days at the latest and is not used to identify the user.</p>

    <p>For more information on conversion tracking, including in particular how you can prevent the collection of data by the cookie set by Google, please see the following link: <a href="http://services.google.com/sitestats/en.html">http://services.google.com/sitestats/en.html</a>.</p>

    <p>You can deactivate the setting of cookies by our website in your browser in whole or in part at any time. If cookies are deactivated, not all functions of our website may be available to you.</p>

    <p>Further information and the applicable data protection provisions of Google can be found at <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>

    <h5>Google Analytics</h5>
    <p>This website uses Google Analytics, a web analytics service provided by Google, Inc ("Google"). Google Analytics uses "cookies", which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of this website is usually transmitted to a Google server in the USA and stored there. In the event that IP anonymization is activated on this website, however, your IP address will be truncated beforehand by Google within member states of the European Union or in other contracting states to the Agreement on the European Economic Area.</p>

    <p>Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. The IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data.</p>

    <p>You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>

    <p>For more information on how Google Analytics handles user data, please see Google's privacy policy: <a href="https://support.google.com/analytics/answer/6004245?hl=en&sjid=933201730655080280-EU">https://support.google.com/analytics/answer/6004245?hl=en&sjid=933201730655080280-EU</a></p>

    <h5>Principles of data processing</h5>
    <p>When processing data, we take into account the processing principles of legality, proportionality, purpose limitation, transparency - in particular the fulfillment of information obligations - and data security.</p>

    <h5>Cookies</h5>
    <p>"theorie24.ch" uses cookies to provide users with the most efficient and user-friendly design of theorie24.ch. Cookies are short text files that are stored on the User's device and serve to analyze the use of the Website and to recognize a User's browser.</p>

    <p>When visiting theorie24.ch, the IP addresses of the Users and information about the Internet browser used are automatically stored for this purpose, among other things. Based on this information, anonymous statistics on the use of theorie24.ch are compiled. In addition, the use of cookies enables theorie24.ch to adapt its offers to the user.</p>

    <p>Most browsers are set to accept cookies. Users are free to generally prevent this in the browser settings or to select the setting so that each storage of a cookie must be accepted. In addition, each stored cookie can be individually deleted again. "theorie24.ch" draws your attention to the fact that the deactivation or deletion of a cookie may result in individual parts of theorie24.ch no longer functioning correctly and completely.</p>

    <h5>Data security</h5>
    <p>All information that you transmit to us is stored on servers within Switzerland. Unfortunately, the transmission of information via the Internet is not completely secure, which is why we cannot guarantee the security of data transmitted to our website via the Internet. However, we secure our website and other systems by technical and organizational measures against loss, destruction, access, alteration or distribution of your data by unauthorized persons.</p>

    <p>To protect your personal information, we use one of the most secure technologies, SSL (Secure Socket Layer) security software, when processing payments online. All information processed using this secure method is encrypted before it is sent to us. We reserve the right to make our payment experience available to an information pool. This can be requested at info@ctmalder.ch.</p>

    <p>"theorie24.ch" does not guarantee that the website theorie24.ch will be available at all times and is not liable for any defective data communication.</p>

    <h5>Changes to this privacy policy</h5>
    <p>We reserve the right to adjust this privacy policy. This Privacy Policy is currently valid and dated 01.09.2023. It can be accessed by you at any time on our website.</p>

    <h5>Rights regarding personal data</h5>
    <p>Of course, you have the right to request information about the data CTM has stored about you. You also have the right to have incorrect data corrected, blocked and deleted. To do so, please contact the address given in the imprint.</p>

    <h5>Final provisions</h5>
    <p>In case of any ambiguities and/or contradictions between the German, and/or the French, Italian and/or English text of this Privacy Policy, only the German text shall prevail.</p>

    <p>This Privacy Policy may be amended at any time, with the current version being published at theorie24.ch.</p>
  `
};
