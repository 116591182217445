import React from 'react';
import {
  TopSection,
  CallToActionSection,
  KeyBenefitsSection,
  VipCodeSection,
  FunctionsSection,
  ShopSection,
  BPTSection
} from './sections/index.sections';
import CommonContainer from '../../ui-components/CommonContainer/CommonContainer';
import styles from './HomePageNew.module.css';

const HomePageNew: React.FC = () => (
  <>
    <CommonContainer className={styles.topContainer}>
      <TopSection />
    </CommonContainer>
    <CommonContainer className={styles.callToActionContainer}>
      <CallToActionSection />
    </CommonContainer>
    <CommonContainer className={styles.keyBenefitsContainer}>
      <KeyBenefitsSection />
    </CommonContainer>
    <CommonContainer className={styles.vipCodeContainer}>
      <VipCodeSection />
    </CommonContainer>
    <CommonContainer className={styles.functionsContainer}>
      <FunctionsSection />
    </CommonContainer>
    {/* <PartnerSection /> */}
    {/* {!IS_WEBVIEW && ( */}
    {/*  <CommonContainer className={styles.appContainer}> */}
    {/*    <AppSection /> */}
    {/*  </CommonContainer> */}
    {/* )} */}
    <CommonContainer className={styles.bptSection}>
      <BPTSection />
    </CommonContainer>
    <CommonContainer className={styles.shopSection}>
      <ShopSection />
    </CommonContainer>
  </>
);

export default HomePageNew;
