import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Container, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import CockpitNotLoggedTopContainer from '../../containers/CockpitNotLoggedTopContainer';
import CockpitCategoriesContainer from '../../containers/CockpitCategoriesContainer';
import { DEMO_CATEGORY } from '../../constants/storageKeys';
import { SET_USER_SELECTED_CATEGORY } from '../../actions/user.action';
import Ads from '../../components/Ads/Ads';
import image from '../../img/myCockpitPage.jpg';
import Image from '../../ui-components/Image/Image';
import styles from './CockpitNotLoggedPage.module.css';

const CockpitNotLoggedPage: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const previousSelectedCategory = Cookies.getJSON(DEMO_CATEGORY);
    if (previousSelectedCategory) {
      dispatch(SET_USER_SELECTED_CATEGORY({ category: previousSelectedCategory }));
    }
  }, [dispatch]);

  return (
    <>
      <div className={styles.CockpitNotLoggedPage}>
        <Container>
          <Row>
            <Col>
              <div className={styles.rulesGroupContent}>
                <a
                  href={t('partnerLogos.cupra.link')}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.imgContainer}
                >
                  <Image src={image} width="100%" />
                </a>
                <CockpitNotLoggedTopContainer />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Ads placement="mobile" />
      <CockpitCategoriesContainer />
      <Ads placement="desktop" />
    </>
  );
};

export default CockpitNotLoggedPage;
