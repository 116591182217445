import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import stylesPage from '../OffersPage/OffersPage.module.css';
import OfferCardContainer from '../../containers/OfferCardContainer';
import VipCard from '../../components/VipCard/VipCard';
import VipCardMobile from '../../components/VipCardMobile/VipCardMobile';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { IS_WEBVIEW_IOS } from '../../constants';
import styles from './ExtendOffersPage.module.css';
import { P3 } from '../../ui-components/P3/P3';

const ExtendOffersPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Container className={styles.topSection}>
        <Row>
          <Col lg={12}>
            <PageTitle className={styles.mainTitle}>{t('extendOffers.voucherBlock.title')}</PageTitle>
            <P3 className={styles.note}>{t('extendOffers.voucherBlock.note')}</P3>
          </Col>
        </Row>
      </Container>
      <div className={cx(stylesPage.offerPageCard, styles.offersBlock)}>
        <OfferCardContainer showTabledView />
      </div>
      <Container>
        {!IS_WEBVIEW_IOS && (
          <Row>
            <Col>
              <div className={styles.offerCardContainer}>
                <h3 className="pageSubtitle">{t('homePage.vipCard.title')}</h3>
                <div className={styles.offerVipCard}>
                  <VipCard />
                </div>
                <VipCardMobile />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ExtendOffersPage;
