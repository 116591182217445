import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { IOS_VERSION, IS_CONTAIN_WEBVIEW_IN_NAVIGATOR, IS_WEBVIEW } from '../constants';
import { isSSR } from '../utils/utils';

const config = {
  apiKey: isSSR() ? 'AIzaSyARDiY5utxN6TXCwXLKUZWvvTWBuDLHXfI' : process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: isSSR() ? 'theorie24pro' : process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

export const firebaseApp = firebase.initializeApp(config);

const checkIfDisablePersistence = () => {
  if (IS_WEBVIEW || IS_CONTAIN_WEBVIEW_IN_NAVIGATOR) {
    if (IOS_VERSION && +IOS_VERSION >= 14) {
      return firebase.firestore().enablePersistence();
    }
    return false;
  }
  return firebase.firestore().enablePersistence();
};

export const offlineMode = checkIfDisablePersistence();
if (offlineMode) {
  offlineMode.catch(err => {
    if (err.code === 'failed-precondition') {
      console.log(`Multiple tabs open, persistence can only be enabled in one tab at a a time.`);
    } else if (err.code === 'unimplemented') {
      console.log(`The current browser does not support all of the features required to enable persistence`);
    }
  });
}

export const db = firebaseApp.firestore();
