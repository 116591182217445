import React from 'react';

export enum SaleItemTopicType {
  Main = 'Main',
  Pro = 'Pro'
}

export interface ISaleItemProps {
  text?: string;
  price?: string;
  description?: string;
  icons: React.ReactNode;
  isLoading: boolean;
  linkUrl: string;
  categoryText: string;
  topic: SaleItemTopicType;
}
