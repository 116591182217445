/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SaleItem } from '../../components';
import TitleH3 from '../../../../ui-components/TitleH3/TitleH3';
import { imageAbsolutePath } from '../../../../utils/utils';
import { useGetOfferList } from '../../../../hooks/dataFetch/useGetOfferList';
import { IRootState } from '../../../../reducer';
import { SaleItemTopicType } from '../../components/SaleItem/Saleitem.types';
import styles from './CallToActionSection.module.css';

const BPTAllIcon = imageAbsolutePath('newCategories/bpt_all.png');
const CIcon = imageAbsolutePath('newCategories/c.png');
const C1Icon = imageAbsolutePath('newCategories/c1.png');
const CZVAll = imageAbsolutePath('newCategories/czv-all.png');
const D = imageAbsolutePath('newCategories/d.png');
const D1 = imageAbsolutePath('newCategories/d1.png');

const CallToActionSection: React.FC = () => {
  const { t } = useTranslation();

  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);

  const { offersList, isFetching } = useGetOfferList();

  const firstPlan = offersList.find(offer => offer.id === 11);
  const secondPlan = offersList.find(offer => offer.id === 12);

  return (
    <section>
      <TitleH3 center text={t('homePageNew.callToActionSection.title')} />

      <div className={styles.saleItemContainer}>
        <SaleItem
          categoryText={t('homePageNew.callToActionSection.truckCategory')}
          linkUrl={`/${isLogged ? 'plan-extension' : 'register'}?offer=${firstPlan?.id}`}
          isLoading={isFetching}
          icons={
            <>
              <img src={CIcon} alt="C" />
              <img src={C1Icon} alt="C1" />
              <img src={D} alt="D" />
              <img src={D1} alt="D1" />
            </>
          }
          topic={SaleItemTopicType.Pro}
          text={firstPlan?.days.toString()}
          price={firstPlan?.cost}
        />
        <SaleItem
          categoryText={t('homePageNew.callToActionSection.taxiCategory')}
          linkUrl={`/${isLogged ? 'plan-extension' : 'register'}?offer=${secondPlan?.id}`}
          isLoading={isFetching}
          icons={<img src={BPTAllIcon} alt="Bpt All" />}
          topic={SaleItemTopicType.Pro}
          text={secondPlan?.days.toString()}
          price={secondPlan?.cost}
        />
        <SaleItem
          categoryText={t('homePageNew.callToActionSection.CZVCategory')}
          linkUrl={`/${isLogged ? 'plan-extension' : 'register'}?offer=${secondPlan?.id}`}
          isLoading={isFetching}
          icons={<img src={CZVAll} alt="CZV All" />}
          topic={SaleItemTopicType.Pro}
          text={secondPlan?.days.toString()}
          price={secondPlan?.cost}
        />
      </div>
    </section>
  );
};

export default CallToActionSection;
