import React, { useEffect, useState, Fragment, useMemo } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { selectLanguage } from '../../reducer/user.selectors';
import { imageAbsolutePath } from '../../utils/utils';
import { GAME_PLAY_STATUS, WHATS_APP_LINK } from '../../constants';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { useGetSelectedColorTheme } from '../../hooks/useGetSelectedColorTheme';
import { IRootState } from '../../reducer';
import styles from './Footer.module.css';

const SEO_LINKS_DE = [
  {
    text: 'Theorieprüfung',
    link: '/theoriepruefung',
    withoutLangPrefix: true
  },
  {
    text: 'Autoprüfung Theorie',
    link: '/autopruefung-theorie',
    withoutLangPrefix: true
  },
  {
    text: 'Theorieprüfung lernen',
    link: '/theoriepruefung-lernen',
    withoutLangPrefix: true
  },
  {
    text: 'Mofa Prüfung',
    link: '/mofa-pruefung',
    withoutLangPrefix: true
  },
  {
    text: 'Rollerprüfung',
    link: '/rollerpruefung',
    withoutLangPrefix: true
  },
  {
    text: 'Auto Theorieprüfung lernen',
    link: '/auto-theoriepruefung-lernen',
    withoutLangPrefix: true
  },
  {
    text: 'Theoretische Autoprüfung',
    link: '/theoretische-autopruefung',
    withoutLangPrefix: true
  },
  {
    text: 'Theoretische Fahrprüfung',
    link: '/theoretische-fahrpruefung',
    withoutLangPrefix: true
  },
  {
    text: 'Auto Theorie lernen',
    link: '/auto-theorie-lernen',
    withoutLangPrefix: true
  },
  {
    text: 'Auto Theorieprüfung',
    link: '/auto-theoriepruefung',
    withoutLangPrefix: true
  },
  {
    text: 'Theorieprüfung Führerschein',
    link: '/theoriepruefung-fuehrerschein',
    withoutLangPrefix: true
  },
  {
    text: 'TCS',
    link: '/tcs',
    withoutLangPrefix: false
  }
];

const footerLogo = imageAbsolutePath('footer-logo.svg');
const footerLogoBlackTheme = imageAbsolutePath('footer-logo-black-theme.svg');
const brandMain = imageAbsolutePath('brand-main.png');
const brandMainBlackThem = imageAbsolutePath('brand-main-black-them.png');

const footerVariants = {
  show: { opacity: 1, display: 'block' },
  hide: {
    opacity: 0,
    transitionEnd: {
      display: 'none'
    }
  }
};

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const [seoLinkList, setSeoLinkList] = useState<{ text: string; link: string; withoutLangPrefix?: boolean }[]>(
    SEO_LINKS_DE
  );
  const currentLanguage = useSelector(selectLanguage);

  const isGameStatusPlay = useSelector(({ game }: IRootState) => game.status) === GAME_PLAY_STATUS ? 'hide' : 'show';

  const { isDarkTheme } = useGetSelectedColorTheme();

  const footerLogoSrc = useMemo(() => (isDarkTheme ? footerLogoBlackTheme : footerLogo), [isDarkTheme]);

  const brandSrc = useMemo(() => (isDarkTheme ? brandMainBlackThem : brandMain), [isDarkTheme]);

  useEffect(() => {
    setSeoLinkList(SEO_LINKS_DE);
  }, [currentLanguage]);

  const renderSEOLinks = () => {
    if (currentLanguage === 'de') {
      return (
        <Col lg={12}>
          <div className={styles.footerSeoContainer}>
            {seoLinkList.map(({ text, link, withoutLangPrefix }, index) => {
              return (
                <Fragment key={link}>
                  <LinkWrapper
                    withoutlanguageprefix={withoutLangPrefix ? 'true' : 'false'}
                    className={styles.footerCopyrightLink}
                    to={link}
                  >
                    {text}
                  </LinkWrapper>
                  {SEO_LINKS_DE.length - 1 !== index && ` | `}
                </Fragment>
              );
            })}
          </div>
        </Col>
      );
    }
    return null;
  };

  return (
    <motion.footer animate={isGameStatusPlay} transition={{ duration: 1 }} variants={footerVariants}>
      <Container>
        <Row>
          <Col className={styles.logoWrapper} lg={3}>
            <LinkWrapper className={styles.footerLogo} to="/">
              <img className="responsive-img" src={footerLogoSrc} alt="footer-logo" />
            </LinkWrapper>

            <ul className={styles.socialLinkList}>
              <li>
                <a
                  className={styles.socialLinkItem}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/theorie24.ch/"
                >
                  <i className="icon-facebook" />
                </a>
              </li>
              <li>
                <a
                  className={styles.socialLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/theorie24.ch/"
                >
                  <i className="icon-instagram" />
                </a>
              </li>
              <li>
                <a
                  className={styles.socialLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={t('info.emailhref')}
                >
                  <i className="icon-email" />
                </a>
              </li>
              <li>
                <a className={styles.socialLinkItem} target="_blank" rel="noopener noreferrer" href={WHATS_APP_LINK}>
                  <i className="icon-whatsapp" />
                </a>
              </li>
              <li>
                <a className={styles.socialLinkItem} rel="noopener noreferrer" href={t('info.telhref')}>
                  <i className="icon-support" />
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={5}>
            <div className={styles.footerTextWrapper}>
              <div className={styles.footerText}>
                <span className={styles.footerTextItem}>{t('footer.CTMAlderAG')}</span>
                <span className={styles.footerTextItem}>{t('footer.GlutzBlotzheimStrasse3')}</span>
                <span className={styles.footerTextItem}>{t('footer.CH4500Solothurn')}</span>
              </div>
              <div className={styles.footerText}>
                {t('info.tellabel')}
                {' · '}
                {t('footer.web')}
              </div>
              <div className={styles.footerCopyright}>
                {`© ${new Date().getFullYear()} `}
                copyright by Reist Telecom AG
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/term">
                  {t('footer.TOC')}
                </LinkWrapper>
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/imprint">
                  {t('footer.imprint')}
                </LinkWrapper>
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/asa">
                  {t('footer.asa')}
                </LinkWrapper>
                {currentLanguage !== 'en' && (
                  <>
                    {' | '}
                    <LinkWrapper className={styles.footerCopyrightLink} to="/tcs">
                      TCS
                    </LinkWrapper>
                  </>
                )}
                {process.env.REACT_APP_VERSION && ` | ${process.env.REACT_APP_VERSION}`}
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className={styles.brandLinkList}>
              <p>{t('footer.moreProducts')}</p>
              <a
                className={styles.brandLinkItem}
                rel="noopener noreferrer"
                target="_blank"
                href={t('footer.fahrschultheorieLink')}
              >
                <img src={brandSrc} width={160} height="auto" alt="fahrschultheorie" />
              </a>
            </div>
          </Col>
          <Col sm={12}>
            <div className={styles.footerCopyrightMobile}>
              {`© ${new Date().getFullYear()} `}
              copyright by Reist Telecom AG
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/term">
                {t('footer.TOC')}
              </LinkWrapper>
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/imprint">
                {t('footer.imprint')}
              </LinkWrapper>
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/asa">
                {t('footer.asa')}
              </LinkWrapper>
              {currentLanguage !== 'en' && (
                <>
                  {' | '}
                  <LinkWrapper className={styles.footerCopyrightLink} to="/tcs">
                    TCS
                  </LinkWrapper>
                </>
              )}
              {process.env.REACT_APP_VERSION && ` | ${process.env.REACT_APP_VERSION}`}
            </div>
          </Col>
          {renderSEOLinks()}
        </Row>
      </Container>
    </motion.footer>
  );
};

export default Footer;
